$(document).ready(() => {
    $(".azerty").keyboard({
        language: 'fr',
        layout: 'fr-azerty-perso',
        autoAcceptOnEsc : true,
        autoAccept : true,
        usePreview : true,
        tabNavigation : true,
        enterNavigation : true,
    });

    $('.pincode').keyboard({
        language: 'fr',
        layout: 'pincode',
        autoAcceptOnEsc : true,
        autoAccept : true,
        usePreview : true,
        tabNavigation : true,
        enterNavigation : true,
    });

    $('.emailKeyboard').keyboard({
        language: 'fr',
        layout: 'fr-email',
        autoAcceptOnEsc: true,
        autoAccept: true,
        usePreview: true,
        tabNavigation: true,
        enterNavigation: true,
    });

})